import { useTranslation } from 'react-i18next'
import classes from './Documents.module.css'
import { Helmet } from 'react-helmet-async'
import Header from '../UI/Header/Header'
import Shadow from '../UI/Shadow/Shadow'

const Documents = () => {
    const { t } = useTranslation()
    const docs = [
        {
            text: t('charter'),
            href: 'files/Ազատազէն_ԿՀԿ_Կանոնադրութիւն.pdf',
        },
        {
            text: t('gift_cards'),
            href: 'files/giftCards.pdf',
        },
        {
            text: t('corporate_packages'),
            href: 'files/Azatazen_Corporate_Offer.pdf',
        },
        {
            text: t('strategic_plan_2024_28'),
            href: 'files/Ռազմավարական_ծրագիր_2024_2028.pdf',
        },
        {
            text: t('rules_of_shooting_comp_2024'),
            href: 'files/Մրցույթ հրաձիգների 2024.pdf',
        },
        {
            text: t('rules_of_the_2024_shoot_competition'),
            href: 'files/Մրցույթ դիպուկների 2024.pdf',
        },
        {
            text: t('annual_package'),
            href: 'files/Տարեկան փաթեթ.pdf',
        },
    ];

    return (
        <>
            <Helmet>
                <title>{t('documents')}</title>
            </Helmet>

            <div className={classes.wrapper}>
                <div className={classes.docs}>
                    <Header className={classes.header} text={t('documents')}></Header>
                    <div className={classes.documents_info}>
                        <ul>
                            {docs.map((doc) => (
                                <li key={doc.href}>
                                    <a href={doc.href} target='_blank' rel='noreferrer'>
                                        {doc.text}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <Shadow className={classes.shadow_wr} src={'/images/photos/docs.png'} alt={'Boy on polygon'} />
            </div>
        </>
    )
}

export default Documents
